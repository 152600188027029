<template>
  <div v-if="loaded">
    <div class="arkaPlan" v-if="!$vuetify.breakpoint.smAndDown">
      <v-img
        class="resim"
        :height="$vuetify.breakpoint.smAndDown ? 500 : 800"
        :gradient="
          `to top , ${$vuetify.theme.themes.light['arkaplan']}, rgba(0,0,0,0.1)`
        "
        :src="profil.kapak"
      />
    </div>
    <v-container
      class="max-width"
      :fluid="$vuetify.breakpoint.smAndDown ? true : false"
      :class="
        `${
          sepeteAt && $vuetify.breakpoint.smAndDown ? ' cartAnimasyon  ' : ''
        } hepsiniEz site bosluk`
      "
    >
      <header-masaustu v-if="!$vuetify.breakpoint.smAndDown" />
      <header-mobil v-if="$vuetify.breakpoint.smAndDown" />
      <v-row>
        <v-col
          sm="2"
          md="4"
          lg="3"
          v-if="!$vuetify.breakpoint.smAndDown"
          class="hidden-sm-and-down"
        >
          <sol-taraf />
        </v-col>
        <v-col
          sm="12"
          md="8"
          lg="6"
          class="menuler pa-0 pa-md-3"
          :style="$vuetify.breakpoint.smAndDown ? 'margin-top:50px' : ''"
        >
          <urunler />
        </v-col>
        <v-col sm="2" md="2" lg="3" class="hidden-md-and-down">
          <sag-taraf />
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="cerezDurum"
      :timeout="-1"
      light
      vertical=""
      :max-width="1400"
      class="ozelSnack"
    >
      <v-banner two-line shaped>
        <div class="pr-4">
          <h3 class="pb-3 primary--text text-h6">
            <v-icon icon="mdi-cookie" class="pr-3" color="primary" size="32">
              mdi-cookie </v-icon
            >{{ $t("cerezBaslik") }}
          </h3>
          <div class="text-caption " style="text-align:justify">
            {{ $t("cerezYazi") }}
          </div>
          <div class="d-flex pt-5">
            <v-spacer />
            <v-btn
              color="primary"
              class="text-capitalize"
              depressed
              @click="cookiesDurum()"
            >
              {{ $t("cerezButon") }}
            </v-btn>
          </div>
        </div>
      </v-banner>
    </v-snackbar>
    <bolgeler />
    <tamam />
    <div v-if="ayarlar.alisverisDurum" class="mobilCart hidden-lg-and-up">
      <div v-if="sepeteAt && sepeteAtGoster" class="sonEklenen pa-6">
        <v-icon class="icon pa-6" color="white" @click="sepeteAtTetik()">
          mdi-close
        </v-icon>
        <div class="pt-16">
          <v-img
            v-if="sonEklenen.urunResim"
            width="160"
            class=" mb-6 rounded-lg"
            :src="sonEklenen.urunResim"
          />
          <div class="text-h4 white--text">
            {{ sonEklenen.urunAd }}
          </div>
          <div class="white--text text-caption mt-1">
            <i18n path="sepeteEklenen" tag="div">
              <template v-slot:miktar>
                <v-chip class="mr-1" color="primary">
                  {{ sonEklenen.miktar }} {{ $t("adet") }}
                </v-chip>
              </template>
              <template v-slot:yaziEklenenMiktar>
                {{ $t("yaziEklenenMiktar") }}
              </template>
            </i18n>
          </div>
        </div>
      </div>

      <v-badge
        v-if="cartUrunler.length > 0"
        class="badgeBen"
        :class="sepetTitre ? 'wiggle' : ''"
        :content="sepetMiktar"
        :value="sepetMiktar"
        color="success"
        overlap
      >
        <v-btn x-large color="primary" fab @click="sepetGoruntule()">
          <v-icon> mdi-cart </v-icon>
        </v-btn>
      </v-badge>
    </div>

    <v-dialog v-model="uyariVer" v-if="uyariVerDurum" max-width="400">
      <v-card color="error">
        <v-card-title class="text-h6 white--text">
          <v-icon color="white" left>mdi-alert</v-icon>
          {{ $t("sistemDurum") }}
        </v-card-title>

        <v-card-text class="white--text">
          <div
            v-if="
              !this.ayarlar.alisverisDurum ||
                (!this.ayarlar.alisverisDurum && !this.magazaDurum)
            "
          >
            {{ $t("sistemKapaliUzunYazi") }}
          </div>
          <div v-if="!this.magazaDurum && this.ayarlar.alisverisDurum">
            {{ $t("sistemKapaliCalismaSaati") }}
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="white">
          <v-spacer></v-spacer>

          <v-btn text class="error--text" @click="uyariVerDurum = false">
            <v-icon size="24" left>
              mdi-check-bold
            </v-icon>
            {{ $t("tamam") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import locale from "date-fns/locale/de";
import differenceInHours from "date-fns/differenceInHours";
import isWithinInterval from "date-fns/isWithinInterval";
import moment from "moment";
import { veri } from "./../db";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "DefaultLayout",
  data: () => ({
    active: false,
    opacity: 1,
    cerezDurum: true,
    sepeteAt: false,
    sepetTitre: false,
    sepeteAtGoster: true,
    sepetMiktar: 0,
    sonEklenen: {},
    profil: {},
    uyariVerDurum: true,
    ayarlar: {
      alisverisDurum: ""
    }
  }),
  firebase: {
    profil: veri.ref("profil"),
    ayarlar: veri.ref("ayarlar")
  },
  computed: {
    ...sync("app", [
      "alisverisTamamla",
      "sepetGoruntuleme",
      "gsapDurum",
      "loaded",
      "bolgeler",
      "musteri",
      "getirUcret",
      "enAzSipasir",
      "teslimatTip",
      "bolgelerDurum",
      "magazaDurum"
    ]),
    ...get("shop", ["zamanlama"]),
    ...get("cart", ["cartUrunler"]),
    uyariVer: {
      // getter
      get: function() {
        return !this.magazaDurum || !this.ayarlar.alisverisDurum;
      }
    }
  },
  watch: {
    cartUrunler: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler(val) {
        if (val.length > 0) {
          this.sonEklenen = _.last(val);
          this.sepetMiktar = _.sumBy(val, "miktar");
          if (this.sepeteAtGoster) {
            this.sepeteAt = true;
            setTimeout(() => {
              this.sepetTitre = true;
            }, 2500);
            setTimeout(() => {
              this.sepeteAt = false;
              setTimeout(() => {
                this.sepetTitre = false;
              }, 1000);
            }, 3000);
          } else {
            this.sepetTitre = true;
            setTimeout(() => {
              this.sepetTitre = false;
            }, 1000);
          }
        }
      }
    },

    gsapDurum: function(val) {
      if (val) {
        ScrollTrigger.matchMedia({
          // desktop
          "(min-width: 960px)": this.masaustuGorunum,
          // setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
          // ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
          // mobile
          "(max-width: 959px)": this.mobilGorunum,
          // The ScrollTriggers created inside these functions are segregated and get
          // reverted/killed when the media query doesn't match anymore.
          // all
          all: function() {}
        });
      }
    }
  },
  created() {
    veri
      .ref()
      .child("renkler")
      .get()
      .then(snapshot => {
        if (snapshot.exists()) {
          this.$vuetify.theme.themes.light = snapshot.val();
        }
      });

    if (this.$cookies.get("cerezKabul")) {
      this.cerezDurum = false;
      if (this.$cookies.get("cerezBolge")) {
        this.bolgelerDurum = false;
        this.$store.set("app/bolge", this.$cookies.get("cerezBolge"));
      }
    }

    this.$store
      .dispatch("app/init", {
        ID: this.$route.query.uID || this.$cookies.get("cerezMusteriID")
      })
      .then(() => {
        this.$store.dispatch("shop/init").then(() => {
          if (_.isObject(this.musteri)) {
            this.cerezDurum = false;
            if (this.$route.query.uID) {
              this.$cookies
                .keys()
                .forEach(cookie => this.$cookies.remove(cookie));
              this.$cookies.set("cerezKabul", true);
              this.$cookies.set("cerezMusteriID", this.$route.query.uID);
            }

            const veri = _.find(this.bolgeler[0], {
              object_key: this.musteri.bolge
            });
            // alert(JSON.stringify(this.musteri, null, 4));
            this.$store.set("app/getirUcret", veri.kargoUcreti);
            this.$store.set("app/enAzSipasir", veri.minSiparis);
            this.$store.set("app/teslimatTip", 1);
            this.$store.set("app/musteriDurum", true);
            if (this.$route.query.uID) {
              this.$router.push({
                name: "Dashboard"
              });
            }
          } else {
            if (
              this.$cookies.get("cerezKabul") &&
              this.$cookies.get("cerezBolge")
            ) {
              this.bolgelerDurum = false;
              this.$store.set("app/bolge", this.$cookies.get("cerezBolge"));
              this.$store.set(
                "app/getirUcret",
                this.$cookies.get("cerezGetirUcret")
              );
              this.$store.set(
                "app/enAzSipasir",
                this.$cookies.get("cerezEnAzSipasir")
              );
              this.$store.set("app/teslimatTip", 1);
              this.$store.set("app/sepetYukseklik", 160);
            } else {
              this.bolgelerDurum = true;
            }
          }
          this.zamanlamaAyar();
          this.$store.set("app/loaded", true);
          setTimeout(() => {
            this.$store.set("app/gsapDurum", true);
          }, 200);
        });
      });
  },
  methods: {
    sepeteAtTetik() {
      this.sepeteAtGoster = false;
      this.sepeteAt = false;
    },
    cookiesDurum() {
      this.$cookies.set("cerezKabul", true);
      this.cerezDurum = false;
    },
    zamanlamaAyar() {
      var now = new Date();

      var year = now.getFullYear();

      var hour = now.getHours();
      var minute = now.getMinutes();
      var date = moment(now);
      var kacinciGun = date.day();
      var kapanis = this.zamanlama[0][kacinciGun].kapanis.split(":");
      var acilis = this.zamanlama[0][kacinciGun].acilis.split(":");
      var kapalimi = this.zamanlama[0][kacinciGun].tip;

      var acilisGun = now.getDate();
      var acilisAy = now.getMonth();
      var ilaveGun;
      if (kapanis[0] === "00") {
        ilaveGun = 1;
      } else {
        ilaveGun = 0;
      }
      var kapanisGun = new Date(
        now.setDate(now.getDate() + ilaveGun)
      ).getDate();
      var kapanisAy = new Date(
        now.setDate(now.getDate() + ilaveGun)
      ).getMonth();

      const magazaAcikSaatler = isWithinInterval(
        new Date(year, acilisAy, acilisGun, hour, minute),
        {
          start: new Date(year, acilisAy, acilisGun, acilis[0], acilis[1]),
          end: new Date(year, kapanisAy, kapanisGun, kapanis[0], kapanis[1])
        }
      );
      console.log();
      let magazaDegerlendirme;
      if (magazaAcikSaatler && kapalimi != 3) {
        magazaDegerlendirme = true;
      } else if (kapalimi == 2) {
        magazaDegerlendirme = true;
      } else {
        magazaDegerlendirme = false;
      }
      let zamanDegerlendirme;
      if (magazaDegerlendirme) {
        zamanDegerlendirme = this.$t("acikAralik", {
          acilis: this.zamanlama[0][kacinciGun].acilis,
          kapanis: this.zamanlama[0][kacinciGun].kapanis
        });
      } else {
        zamanDegerlendirme = this.$t("kapaliYazi");
      }
      if (kapalimi == 2) {
        zamanDegerlendirme = this.$t("24Yazı");
      }
      this.$store.set("app/magazaDurum", magazaDegerlendirme);
      this.$store.set("app/zamanalamaDurum", zamanDegerlendirme);
    },
    masaustuGorunum() {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".arkaPlan",
            start: "top -1",
            end: "bottom -1000%",
            scrub: 1,
            pin: true,
            ease: "circ.out ",
            markers: false,
            pinSpacing: false
          }
        })
        .to(".resim", {
          height: 300
        })
        .to(
          ".resim",
          {
            opacity: 0.5
          },
          0
        );
    },
    mobilGorunum() {},
    sepetGoruntule() {
      this.$store.set("app/alisverisTamamla", true);
      this.$store.set("app/sepetGoruntuleme", true);
    }
  }
};
</script>
<style lang="scss">
body,
html {
  overflow-x: hidden;
}
.container {
  max-width: 1400px;
}
@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .container {
    max-width: 1300px;
  }
}

.hepsiniEz {
  transition: 0.5s;
}
.hepsiniEz.cartAnimasyon {
  opacity: 0.05;
}
.mobilCart {
  position: fixed;
  bottom: 2em;
  right: 2em;
  z-index: 2;
  .sonEklenen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .icon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.wiggle {
  animation: wiggle 3.5s infinite;
}
.badgeBen {
  .v-badge__wrapper {
    left: -10px;
    top: 10px;
  }
  .v-badge__badge {
    border-radius: 20px;
    font-size: 16px;
    height: 30px;
    line-height: 23px;
    min-width: 30px;
  }
}
.arkaPlan {
  position: absolute;
  z-index: 1;
  width: 100%;
}
.site {
  position: relative;
  z-index: 2;
}
@keyframes wiggle {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(35px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(35px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(35px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
}
.ozelSnack {
  .v-snack__content {
    padding: 0 !important;
  }
}
</style>
